:root {
  --fs-xs: 10px;
  --fs-xsm: 14px;
  --fs-sm: 16px;
  --fs-xBase: 18px;
  --fs-base: 20px;
  --fs-md: 22px;
  --fs-lg: 24px;
  --fs-xl: 26px;
  --fs-2xl: 36px;
  --fs-3xl: 37px;
  --fs-4xl: 46px;
  --fs-5xl: 48px;
  --fs-custom: 18px;
  /* DEFINE COLOR NAME WITHOUT QUOTES */
  --color-light-black: #231f20;
  --color-yellow: #ffde17;
}

.fs_xs {
  font-size: var(--fs-xs);
}

.fs_xsm {
  font-size: var(--fs-xsm);
}

.fs_sm {
  font-size: var(--fs-sm);
}

.fs_xBase {
  font-size: var(--fs-xBase);
}

.fs_base {
  font-size: var(--fs-base);
}

.fs_custom {
  font-size: var(--fs-custom);
}

.fs_md {
  font-size: var(--fs-md);
}

.fs_lg {
  font-size: var(--fs-lg);
}

.fs_xl {
  font-size: var(--fs-xl);
}

.fs_2xl {
  font-size: var(--fs-2xl) !important;
}

.fs_3xl {
  font-size: var(--fs-3xl);
}

.fs_4xl {
  font-size: var(--fs-4xl);
}

.fs_5xl {
  font-size: var(--fs-5xl);
}

.lh_normal {
  line-height: normal;
}

/* DEIFNE COLOR CLASSES */
.text_light_black {
  color: var(--color-light-black);
}

.bg_yellow {
  background: var(--color-yellow);
}

@media (max-width: 1199.98px) {
  :root {
    --fs-xsm: 14px;
    --fs-sm: 16px;
    --fs-base: 12px;
    --fs-xBase: 13px;
    --fs-md: 12px;
    --fs-lg: 20px;
    --fs-xl: 13px;
    --fs-2xl: 16px;
    --fs-3xl: 33px;
    --fs-4xl: 22px;
    --fs-5xl: 22px;
  }
}

@media (max-width: 767.98px) {
  :root {
    --fs-xsm: 14px;
    --fs-sm: 12px;
    --fs-md: 12px;
    --fs-xl: 18px;
    --fs-2xl: 20px;
    --fs-3xl: 26px;
    --fs-custom: 12px;
  }
}

@media (max-width: 575.98px) {
  :root {
    --fs-xsm: 14px;
    --fs-sm: 12px;
    --fs-md: 12px;
    --fs-lg: 14px;
    --fs-xl: 13px;
    --fs-2xl: 20px;
    --fs-3xl: 26px;
    --fs-4xl: 20px;
    --fs-5xl: 20px;
  }
}

/* DEFINE NEW CODE CSS  */
.presale_content_box {
  background: #bcbec0;
  border-radius: 27.52px;
  max-width: 620px;
  padding: 35px 46px 12px;
  box-shadow: 0px 4.4px 4.4px 0px rgba(0, 0, 0, 0.58),
    /* Outer box shadow */ 0px 4.4px 4.4px 0px rgba(255, 255, 255, 0.41) inset;
}

.time_box {
  background: var(--color-yellow) !important;
  border-radius: 27.52px;
  padding: 14px 64px !important;
}

.custom_border {
  border: 2.08px solid #ffffff;
}

.lh_121 {
  line-height: 121%;
}

.h_22 {
  height: 22px;
}

input:focus-visible {
  outline: none !important;
}

.custom_input_style {
  padding: 10px 8px;
  border-radius: 14px;
}

.content_box {
  max-width: 232px;
}

.wallet_btn {
  color: var(--color-yellow);
  background: black;
  padding: 9px 12px;
  border-radius: 14px;
  border: 2.08px solid #ffffff;
  display: inline-block;
  width: 232px;
  transition: all 0.3s ease-in-out;
}

.wallet_btn:hover {
  background: var(--color-yellow);
  color: var(--color-light-black);
}

.buy_btn {
  color: var(--color-light-black);
  background: var(--color-yellow);
  padding: 9px 28px;
  border-radius: 14px;
  width: 232px;
  border: 2.08px solid #ffffff;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.buy_btn_dull {
  color: gray;
  background: rgb(200, 200, 200);
  padding: 9px 28px;
  border-radius: 14px;
  width: 232px;
  border: 2.08px solid #ffffff;
  display: inline-block;
  cursor: not-allowed !important;
}
.buy_btn:hover {
  color: var(--color-yellow);
  background: black;
}

.hen_egg_img {
  max-width: 460px;
}

.group_egg_hover .hen_egg_img {
  transition: all 0.3s linear;
}

.group_egg_hover:hover .hen_egg_img {
  scale: 1.02;
}

.custom_max_width {
  max-width: 1096px !important;
}

@media (max-width: 1199.98px) {
  .time_box {
    padding: 8px 28px !important;
  }

  .presale_content_box {
    padding: 26px 20px 12px;
    max-width: 540px;
  }

  .content_box {
    max-width: 140px;
  }

  .buy_btn,
  .buy_btn_dull,
  .wallet_btn {
    width: 140px;
    padding: 10px 8px !important;
  }

  .hen_egg_img {
    max-width: 290px;
  }

  .page_logo {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 767.98px) {
  .time_box {
    padding: 8px 28px !important;
  }

  .presale_content_box {
    padding: 16px 14px 12px;
    border-radius: 20px;
    max-width: unset;
  }

  .content_box {
    max-width: 120px;
  }

  .buy_btn,
  .buy_btn_dull,
  .wallet_btn {
    font-size: 10px;
    width: 120px;
    padding: 8px !important;
    border-radius: 8px;
  }

  .hen_egg_img {
    max-width: 270px;
  }

  .custom_input_style {
    padding: 6.5px;
    border-radius: 8px;
  }
}

@media (max-width: 575.98px) {
  .hen_egg_img {
    max-width: unset;
  }

  .content_box,
  .buy_btn,
  .buy_btn_dull,
  .wallet_btn {
    max-width: unset;
  }

  .buy_btn,
  .buy_btn_dull,
  .wallet_btn {
    width: 100%;
  }

  .page_logo {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 374.98px) {
  .presale_content_box {
    max-width: 300px !important;
  }
}

.progress-bar {
  width: 100%;
  height: 18px;
  border-radius: 10px;
  background: rgb(30, 30, 30);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid white;
}
.progress-bar div {
  width: 0%;
  height: 100%;
  background: rgb(255, 230, 8);
}
