.selector {
  position: relative;
  z-index: 100000000000;
}
.language-current img {
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  margin-left: 10px;
}
.language-selector {
  position: absolute;
  width: 180px;
  z-index: 1000000;
  top: 40px;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  background: black;
  padding: 6px 10px;
  height: 235px;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: 0px 0px 10px black;
}
.language-selector a {
  width: 100%;
  background: transparent;
  border: none;
  font-family: "Montserrat";
  color: white !important;
  border-bottom: 1px solid rgb(60, 60, 60);
  font-size: 15px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 5px 0;
}
.language-selector a img {
  width: 22px;
}

@media (max-width: 550px) {
  .language-selector {
    height: 105px;
    transform: translate(0);
    left: auto;
    right: 0;
  }
}
