@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.ff_Montserrat {
  font-family: "Montserrat", "sans-serif";
}
html,
body {
  overflow-x: hidden !important;
}
::-webkit-scrollbar {
  display: none;
}
.cursor_pointer {
  cursor: pointer !important;
}
.page_bg {
  background-image: url("../src/assets/images/png/page-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
}

@font-face {
  font-family: victorycomics;
  src: url("./assets/fonts/victorycomics.ttf");
}
.egg_img {
  transition: all 300ms linear;
}
.egg_img:hover {
  scale: 1.03;
}

.ff_Victory {
  font-family: victorycomics;
}
.para_leading {
  line-height: 110%;
}
.gap_12 {
  gap: 12px;
}
.hero_heading {
  line-height: 75%;
  letter-spacing: 2px;
}
.btn_hover {
  transition: all 200ms linear;
  margin-top: 12px;
}
.btn_hover:hover {
  color: white;
  z-index: 2 !important;
}
.nav_whitepaper span,
.nav_whitepaper a {
  transition: all 200ms linear;
}
.nav_whitepaper span:hover,
.nav_whitepaper a:hover {
  color: #b66a23 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
.mt_2{
  margin-top: 2px;
}
.egg_heading{
  line-height: 112%;
}
.only_hen{
  left: -18%;
  width: 90%;
  z-index: 0;
  bottom: 5%;
}

@media (min-width: 576px) {
  .connect_btn {
    font-size: 18px !important;
  }
  .custom_container {
    max-width: 576px  !important;
  }
}

@media (min-width: 768px) {
  .custom_container {
    max-width: 700px  !important;
  }
}

@media (min-width: 992px) {
  .para_leading {
    line-height: 120%;
  }
}

@media (min-width: 1100px) {
  .para_leading {
    line-height: 124%;
  }

}
@media (min-width: 1200px) {
  .custom_container {
    max-width: 1140px !important;
  }
  .custom_input_style {
  padding: 7.5px 8px !important;
  border-radius: 14px;
}
}






